import React, { useRef } from 'react'
import Navbar from '../Elements/Navbar'
import Footer from '../Elements/Footer'
import './CookiePolicy.css'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

function CookiePolicy() {
  const {t} = useTranslation();
  const navRef = useRef()
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Nuestra política de cookies | MicrocementPRO</title>
  
      </Helmet>
      <Navbar navRef={navRef}/>
      <div className='content-wrapper' ref={navRef}>
            <div className='about-us'>
                <h1 className='title'>{t('cookies_policy_title')}</h1>
                <h3>{t('cookies_policy_last_update')}</h3>

                <h2>{t('cookies_policy_intro_heading')}</h2>
                <p>{t('cookies_policy_intro_text')}</p>

                <h2>{t('cookies_policy_what_are_cookies_heading')}</h2>
                <p>{t('cookies_policy_what_are_cookies_text')}</p>

                <h2>{t('cookies_policy_types_heading')}</h2>

                <ol className='tokens-list'>
                    <li>
                        <h3>{t('cookies_policy_types_ga_heading')}</h3>
                        <p>{t('cookies_policy_types_ga_purpose')}</p>
                        <p>{t('cookies_policy_types_ga_expiry')}</p>
                    </li>
                    <li>
                        <h3>{t('cookies_policy_types_cookie_consent_heading')}</h3>
                        <p>{t('cookies_policy_types_cookie_consent_purpose')}</p>
                        <p>{t('cookies_policy_types_cookie_consent_expiry')}</p>
                    </li>
                    <li>
                        <h3>{t('cookies_policy_types_csrftoken_heading')}</h3>
                        <p>{t('cookies_policy_types_csrftoken_purpose')}</p>
                        <p>{t('cookies_policy_types_csrftoken_expiry')}</p>
                    </li>
                </ol>

                <h2>{t('cookies_policy_third_party_cookies_heading')}</h2>
                <p>{t('cookies_policy_third_party_cookies_text')}</p>

                <h2>{t('cookies_policy_manage_cookies_heading')}</h2>
                <p>{t('cookies_policy_manage_cookies_text')}</p>

                <p>{t('cookies_policy_manage_cookies_resources')}</p>

                <ol className='browsers-list'>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=es&co=GENIE.Platform%3DDesktop" rel="nofollow">{t('cookies_policy_google_chrome')}</a></li>
                    <li><a href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-" rel="nofollow">{t('cookies_policy_mozilla_firefox')}</a></li>
                    <li><a href="https://support.microsoft.com/es-es/topic/ver-las-cookies-en-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879#:~:text=Abre%20Microsoft%20Edge%20y%20luego,cookies%20y%20datos%20del%20sitio." rel="nofollow">{t('cookies_policy_microsoft_edge')}</a></li>
                    <li><a href="https://www.apple.com/legal/privacy/es/cookies/#:~:text=En%20tu%20iPad%2C%20iPhone%20o,determinadas%20secciones%20si%20las%20desactivas." rel="nofollow">{t('cookies_policy_safari')}</a></li>
                </ol>

                <h2>{t('cookies_policy_changes_heading')}</h2>
                <p>{t('cookies_policy_changes_text')}</p>

                <h2>{t('cookies_policy_contact_heading')}</h2>
                <p>{t('cookies_policy_contact_text')}</p>
            </div>
        </div>

    <Footer/>
    </div>
  )
}

export default CookiePolicy
