
import React from 'react';
import {useForm} from "react-hook-form";
import './ContactForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
function ContactForm() {
  const {t} = useTranslation();
    const {register, formState: {errors}, handleSubmit} = useForm();

    const onSubmit = async (data) => { console.log(data); 
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      const response = await fetch(['https://microcement-backend.onrender.com/send'], requestOptions);
      const jsonData = await response;
      if (jsonData.status === 200) {
        alert('Email sent successfully!');
      } else {
        alert('There was an error sending your email.');
      }
    
    }

    return(
      <form className='contact-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='form-elements-wrapper'>
            <input
                type="text" id="name" name="name"
                placeholder={t('contact_form_name_placeholder')}
                {...register('name', {
                    required: true
                })}
            />
            <hr/>
            {errors.name?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('contact_form_name_required')}</p>}
            <input
                id="email" name="email"
                placeholder={t('contact_form_email_placeholder')}
                {...register('email', {
                    required: true,
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
                })}
            />
            <hr/>
            {errors.email?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('contact_form_email_required')}</p>}
            {errors.email?.type === 'pattern' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('contact_form_email_pattern')}</p>}
            <input
                id="phone" name="phone"
                placeholder={t('contact_form_phone_placeholder')}
                {...register('phone')}
            />
            <hr/>
            {errors.phone?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('contact_form_phone_required')}</p>}
            <textarea
                id="description"
                name="description"
                placeholder={t('contact_form_description_placeholder')}
                {...register('description', {
                    required: true
                })}
            />
            <hr/>
            {errors.description?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('contact_form_description_required')}</p>}
    
            <div className='btn-wrapper'><button className='btn btn-normal' type="submit">{t('contact_form_submit')}</button></div>
            <p className='form-privacy'>{t('contact_form_privacy')}</p>
        </div>
      </form>
    )
}

export default ContactForm
