import React, { useRef } from 'react'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js';
import colorFiles from '../ColorFiles.js';
import './Colors.css'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Colors() {
    const {t} = useTranslation();
    const navRef = useRef();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const colorNames = colorFiles.map((filePath) => {
    // Split the file path by '/' to get the filename
    const parts = filePath.split('/');
    // Get the last part of the split (the filename)
    const fileName = parts[parts.length - 1];
    // Remove the file extension (assuming it's always '.png' or '.jpg')
    const colorName = fileName.replace(/\.(png|jpg)$/, '');
    // Split the color name by '-' to get the actual color name
    const colorParts = colorName.split('-');
    // Join the color parts together with spaces
    return colorParts.join(' ');
    });
    return (
        <div>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>¿Qué colores de microcemento puede elegir? | MicrocementPRO</title>
                <meta name="description" content="Explora la gama de colores disponibles con MicrocementPRO ¡más de 35 colores personalizados para tu reforma con microcemento! Elige y pide presupuesto "/>
            </Helmet>
            <Navbar navRef={navRef}/>
            <div className='content-wrapper' ref={navRef}>
                <div className='colors-section'>
                    <h1 className='title'>{t("colores_disponibles")}</h1>
                    <div className='image-grid'>
                        
                        {colorFiles.map((file, index) => (
                            <img className='img-item' src={file} key={colorNames[index]} alt={colorNames[index]}/>
                        ))}
                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default Colors
