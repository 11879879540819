
import React, {Component, useEffect, useState} from 'react'
import './SmallCarousel.css'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function SmallCarousel({imgsObj, text=""}) {
    const [imgIndex, setImgIndex] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    useEffect( () => {
        
        const interval = setInterval(() => {
            if(autoPlay){
                changeImage(imgIndex, 'next');
            }
        }, 4000);
        return () => clearInterval(interval);
        
    })

    const changeImage = (imgIndex, direction) => {
        if( direction === 'prev'){
            setImgIndex((imgIndex - 1 + imgsObj.length) % imgsObj.length);
        }
        else if( direction === 'next'){
            setImgIndex((imgIndex + 1) % imgsObj.length);
        }
        
    }

    const nextImage = () => {
        setAutoPlay(false);
        changeImage(imgIndex, 'next');
    }
    const prevImage = () => {
        setAutoPlay(false);
        changeImage(imgIndex, 'prev');
    }

    return (
        <div className='small-carousel-wrapper'>
        <div className='small-carousel' style={{backgroundImage: `url(${imgsObj[imgIndex].img_url})`}}>
            <button className='btn-left btn-carousel-small' aria-label='Anterior'>
                <FontAwesomeIcon icon={faChevronLeft} size='lg' onClick={nextImage} />
            </button>
            <button className='btn-right btn-carousel-small' aria-label='Siguiente' onClick={prevImage}>
                <FontAwesomeIcon icon={faChevronRight} size='lg' />
            </button>
            
        </div>
        <h2 className='title small-carousel-text'>{text}</h2>
        </div>

        

    )
}

export default SmallCarousel
