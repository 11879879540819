import React, { useRef, useState } from 'react'
import './FAQ.css'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js';
import QuestionAnswer from '../Elements/QuestionAnswer.js';
import FormSection from '../Elements/FormSection.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function FAQ() {
    const {t} = useTranslation();
    const navRef = useRef();

    return (
    <div>
        <Helmet>
            <meta charSet='utf-8'/>
            <title>Preguntas frecuentes y dudas sobre microcemento | MicrocementPro</title>
            <meta name="description" content="Información, preguntas frecuentes y dudas sobre el microcemento bajo nuestra experiencia en MicrocementPro tras más de 20 años en el sector"/>
        </Helmet>
        <Navbar navRef={navRef}/>
        <div className='content-wrapper' ref={navRef}>
            <div className='faq'>
                <h1 className='title'>{t('preguntas_frecuentes')}</h1>
                <QuestionAnswer question={t('ventajas_microcemento')}/>
                <QuestionAnswer question={t('resistencia_microcemento')}/>
                <QuestionAnswer question={t('superficies_microcemento')}/>
                <QuestionAnswer question={t('diferencias_microcemento_hormigon')}/>
            </div>
            <FormSection bgColor={"#e1e1e1"}/>
        </div>
        <Footer/>
    </div>
    )
}

export default FAQ
