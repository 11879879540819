import React from 'react'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js'
import { useRef } from 'react'
import './AboutUs.css'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

function AboutUs() {
    const navRef = useRef();
    const {t} = useTranslation();
    return (
        <div>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>Nuestra historia como instaladores de microcemento | MicrocementPRO</title>
                <meta name="description" content="Conoce nuestra breve historia como instaladores profesionales y especialistas en instalaciones y reformas de microcemento desde hace más de 20 años."/>
            </Helmet>
            <Navbar navRef={navRef}/>
            <div className='content-wrapper' ref={navRef}>
                <div className='about-us'>
                    <h1 className='title'>{t('nosotros_titulo')}</h1>
                    <p>{t('nosotros_p1')}</p>
                    <p>{t('nosotros_p2')}</p>
                    <p>{t('nosotros_p3')}</p>
                    <p>{t('nosotros_p4')}</p>
                    <p>{t('nosotros_p5')}</p>
                    <p>{t('nosotros_p6')}</p>
                    <p>{t('nosotros_p7')}</p>
                    <p>{t('nosotros_p8')}</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AboutUs
