const trabajosData = [
    {
        img_url: "/project_images/hilton_bar/cover.jpg",
        text: "Hilton Sky Bar Heathrow – Brillo Negro",
        images: [
            "/project_images/hilton_bar/1.jpg",
            "/project_images/hilton_bar/2.jpg"
        ],
        alts: [
            "Bar con techo de microcemento",
            "Bar con camareros trabajando"
        ],
    },
    
    {
        img_url: "/project_images/occitane/1.jpg",
        text: "L'Occitane St. Pancras Station London",
        images: [
            "/project_images/occitane/1.jpg",
            "/project_images/occitane/2.jpg",
            "/project_images/occitane/3.jpg",
            "/project_images/occitane/4.jpg"
        ],
        alts: [
            "Mostrador de l'Occitane con paredes de microcemento",
            "Fachada de la tienda l'Occitane",
            "Interior de l'Occitane mostrando las paredes de microcemento",
            "Estantes con comida"
        ]
    },
    {
        img_url: "/project_images/bathroom1/bath1.jpeg",
        text: "Cuarto de baño residencial - Suelo y paredes",
        images: [
            "/project_images/bathroom1/bath1.jpeg",
            "/project_images/bathroom1/bath2.jpeg",
            "/project_images/bathroom1/bath3.jpeg",
            "/project_images/bathroom1/bath4.jpeg",
        ],
        alts: [
            "Vista del baño desde la puerta",
            "Ducha con paredes y suelo de microcemento",
            "Bañera y suelo de microcemento",
            "Otra vista de la ducha"
        ]
    },
    {
        img_url: "/project_images/maggies_leeds/2.jpg",
        text: "Maggies Leeds – Suelo con Acabado Brillante",
        images: [
            "/project_images/maggies_leeds/1.jpg",
            "/project_images/maggies_leeds/2.jpg",
            "/project_images/maggies_leeds/3.jpg",
            "/project_images/maggies_leeds/4.jpg",
            "/project_images/maggies_leeds/5.jpg",
        ],
        alts: [
            "Cobertura de la CNN del exterior del edificio",
            "Interior del edificio con suelo de microcemento",
            "Vista del suelo brillante",
            "Cocina con suelo de microcemento",
            "Vista del suelo y escaleras con barandilla"
        ]
    },
    {
        img_url: "/project_images/exterior/1.jpg",
        text: "Paredes de exterior - Tower Hill",
        images: [
            "/project_images/exterior/1.jpg",
            "/project_images/exterior/2.jpg",
            "/project_images/exterior/3.jpg"
        ],
        alts: [
            "Patio con pared de microcemento",
            "Vista superior del muro",
            "Vista alternativa del muro"
        ]
    },
    {
        img_url: "/project_images/kitchen/1.jpg",
        text: "Romford Residential Project - Suelo de Cocina y Patio",
        images: [
            "/project_images/kitchen/1.jpg",
            "/project_images/kitchen/2.jpg",
            "/project_images/kitchen/3.jpg",
        ],
        alts: [
            "Cocina con isla y suelo de microcemento",
            "Interior de la cocina",
            "Vista del suelo brillante"
        ]
    },
    {
        img_url: "/project_images/nightclub/nightclub3.jpg",
        text: "Club nocturno en Canterbury - Barra de bar y suelo",
        images: [
            "/project_images/nightclub/nightclub1.jpg",
            "/project_images/nightclub/nightclub2.jpg",
            "/project_images/nightclub/nightclub3.jpg",
            "/project_images/nightclub/nightclub4.jpg"
        ],
        alts: [
            "Vista cercana de la barra y el suelo, con una persona caminando",
            "Barra con acabado de microcemento",
            "Barra brillante",
            "Vista alternativa de la barra"
        ]
    },
    {
        img_url: "/project_images/getty/cover.jpg",
        text: "Getty Images Gallery Wembley Walk - Suelo",
        images: [
            "/project_images/getty/1.jpg",
            "/project_images/getty/2.jpg",
            "/project_images/getty/3.jpg",
            "/project_images/getty/4.jpg"
        ],
        alts: [
            "Interior de la galeria de Getty Images",
            "Esquina de la galeria",
            "Vista alternativa de la esquina",
            "Suelo con acabado de microcemento"
        ]
    },
    {
        img_url: "/project_images/residential_stairs/1.jpg",
        text: "Residential Project Clapham - Paredes, Suelos y Escaleras",
        images: [
            "/project_images/residential_stairs/1.jpg",
            "/project_images/residential_stairs/2.jpg",
            "/project_images/residential_stairs/3.jpg",
            "/project_images/residential_stairs/5.jpg"
        ],
        alts: [
            "Escaleras, pared y suelo de microcemento",
            "Mueble empotrado en pared de microcemento",
            "Pequeña cocina con escaleras de microcemento",
            "Vista ampliada del suelo y las escaleras de microcemento"

        ]
    },

];

export default trabajosData;