import React, {useState} from 'react';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ScrollButton.css'
const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	if(typeof window !== 'undefined'){
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
			/* you can also use 'auto' behaviour
				in place of 'smooth' */
			});
	}
	
};
if(typeof window !== 'undefined')
	window.addEventListener('scroll', toggleVisible);

return (
	<button className='scroll-button' aria-label="Scroll up" onClick={scrollToTop}>
	<FontAwesomeIcon icon={faArrowUp} size={"2xs"} />
	</button>

);
}

export default ScrollButton;
