
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import './LanguageSelector.css';
import i18n from "../i18n";

let languages = [
  {
    code: "es",
    name: "Español",
    country_code: "es-ES",
  },
  {
    code: "en",
    name: "English",
    country_code: "en-GB",
  },
  {
    code: "sv",
    name: "Svenska",
    country_code: "sv-SE",
  }
];

const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
      .split('-')
      .pop()
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

const LanguageSelector = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('en');
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="language-selector">
      <div className="language-icon" onClick={() => setIsOpen(!isOpen)}>
        🌐
      </div>
      {isOpen && (
        <div className="language-dropdown">
         {languages.map((lang) => (
            <div key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
              <span role="img" aria-label={`${lang.name} Flag`}>
                {getFlagEmoji(lang.country_code)}
              </span>
              {lang.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;