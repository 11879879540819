import React, { useRef } from 'react'
import './TiposMicrocemento.css'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js';
import FormSection from '../Elements/FormSection.js';
import { useEffect } from 'react';

function TiposMicrocemento() {
    const navRef = useRef();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
            })}
      , [])
  return (
    <div>
      <Navbar navRef = {navRef}/>
      <div className='content-wrapper' ref={navRef}>
        <div className='types-micro'>
            <h1 className='title section-title'>TIPOS DE MICROCEMENTO</h1>
            <div className='micro-tipo'>
                <img src='./cement-types/fino.png'/>
                <h1 className='title'>PRO FINO</h1>
                <p>Completely smooth and slick, PRO FINE is our most bespoke finish providing 
                    almost a glass-feel to the blind finger. It can also be created with a higher 
                    level of ‘visual movement’ (also called ‘stucco effect’) and it suitable for 
                    walls, floors and ceilings both indoors and outdoors.
                </p>
            </div>
            <div className='micro-tipo'>
                <img src='./cement-types/deck.png'/>
                <h1 className='title'>PRO MEDIUM</h1>
                <p>Completely smooth and slick, PRO FINE is our most bespoke finish providing 
                    almost a glass-feel to the blind finger. It can also be created with a higher 
                    level of ‘visual movement’ (also called ‘stucco effect’) and it suitable for 
                    walls, floors and ceilings both indoors and outdoors.
                </p>
            </div>
            <div className='micro-tipo'>
                <img src='./cement-types/marmo.png'/>
                <h1 className='title'>PRO MARMOLINO</h1>
                <p>Completely smooth and slick, PRO FINE is our most bespoke finish providing 
                    almost a glass-feel to the blind finger. It can also be created with a higher 
                    level of ‘visual movement’ (also called ‘stucco effect’) and it suitable for 
                    walls, floors and ceilings both indoors and outdoors.
                </p>
            </div>
            <div className='micro-tipo'>
                <img src='./cement-types/BR1.jpg'/>
                <h1 className='title'>PRO BR1</h1>
                <p>Completely smooth and slick, PRO FINE is our most bespoke finish providing 
                    almost a glass-feel to the blind finger. It can also be created with a higher 
                    level of ‘visual movement’ (also called ‘stucco effect’) and it suitable for 
                    walls, floors and ceilings both indoors and outdoors.
                </p>
            </div>
        </div>

        <FormSection bgColor="#e1e1e1"/>
      </div>
      <Footer/>
    </div>
  )
}

export default TiposMicrocemento
