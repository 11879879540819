import React, { useState } from 'react'
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';


function Navbar({navRef}) {
    const {t} = useTranslation();
    const [dropdownClass, setDropdownClass] = useState('dropdown-hidden');
    const showNavbar = () => {
        navRef.current.classList.toggle("move_down");
    }

    const showDropdown = () => {
        if(dropdownClass === 'dropdown-shown'){
            setDropdownClass('dropdown-hidden')
        }
        else{
            setDropdownClass('dropdown-shown');
        }

    }
    const navigate = useNavigate();

    return (
        <div>
    <div className='topbar-wrapper'>
        <div className='topbar'>
            <a href="/"><img src="/final_cropped_logo.png" alt={t('logo_alt')}></img></a>

            <ul>
                <li><a href="tel:+34614260296" rel="nofollow"><FontAwesomeIcon icon={faPhone} size='sm' className='phone-icon' /> 614 26 02 96</a></li>
            
                <li><div className='btn-wrapper btn btn-normal'><a href='/presupuesto'><p className='btn-text'>{t('presupuesto_gratis')}</p></a></div></li>
                <li><LanguageSelector/></li>
                <li><button onClick={showNavbar} className='burger-btn'>
                <FontAwesomeIcon icon={faBars} size='2xl'/>
                </button></li>
            </ul>
        </div>
    </div>
    
    <div className='navbar'>
        <nav>
        <ul>
            <li><a href="/">{t('inicio')}</a></li>
            <li>
                
                <button className="microcement-nav" onClick={showDropdown}>
                    {t('microcementos')}
                </button>
                <ul className={dropdownClass}>
                    <li className='dropdown-item'><a href="/por-que-elegir-microcemento">{t('por_que_elegir_microcemento')}</a></li>
                    {/* <li className='dropdown-item'><a href="/tipos-microcemento">{t('tipos_microcementos')}</a></li> */}
                    <li className='dropdown-item'><a href="/preguntas-frecuentes">{t('preguntas_frecuentes')}</a></li>
                </ul>
                
            </li>
            <li><a href="/proyectos-previos">{t('trabajos_previos_nav')}</a></li>
            <li><a href="/colores" rel="nofollow">{t('colores')}</a></li>
            <li><a href="/nosotros">{t('nosotros')}</a></li>
            <li><a href="/contacto" rel="nofollow">{t('contacto')}</a></li>
        </ul>
        </nav>
    </div>
</div>
    )
}

export default Navbar
