const sliderData = [
    {
        img_url: "sin_juntas.jpeg",
        text: "Elegante, sin juntas y totalmente personalizable"
    },
    {
        img_url: "negocio2.jpeg",
        text: "Instalado por auténticos expertos"
    },
    {
        img_url: "bathroom.jpg",
        text: "Cualquier color, cualquier superficie"
    },
    {
        img_url: "bathroom2.jpeg",
        text: "Duchas, baños y piscinas con microcemento"
    },
    {
        img_url: "residencial1.jpeg",
        text: "Acabados superiores y duraderos"
    },
    {
        img_url: "carousel6.jpg",
        text: "Garantía de líder de instalaciones"
    },
    {
        img_url: "negocio1.jpeg",
        text: "Aplicación residencial y comercial"
    },

];

export default sliderData;