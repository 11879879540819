import React from 'react'
import { useRef } from 'react'
import Navbar from '../Elements/Navbar.js';
import Footer from '../Elements/Footer.js';
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../Elements/ContactForm.js';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Contact() {
    const {t} = useTranslation();
    const navRef = useRef();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>Teléfono y contacto empresa de microcemento | MicrocementPro</title>
                <meta name="description" content="Contacte ahora con MicrocementPro: empresa de instalación de microcemento para reformas en Alicante, Valencia, Murcia, Ibiza...Llame al 614 26 02 96"/>
            </Helmet>
            <Navbar navRef={navRef}/>
            <div className='content-wrapper' ref={navRef}>
                <div className='contact-section'>
                    <h1 className='title'>{t('contactanos_titulo')}</h1>
                    <div className='contact-form-div'>
                        <div className='contact-form-wrapper'>
                            <ContactForm/>
                        </div>
                        <div className='contact-info-wrapper'>
                            <div className='contact-item'>
                                <FontAwesomeIcon icon={faEnvelope} size="xl"/>
                                <b>Email</b>
                                <p>info@microcementpro.es</p>
                            </div>

                            <div className='contact-item'>
                                <FontAwesomeIcon icon={faPhone} size="xl"/>
                                <b>{t('contacto_telefono_titulo')}</b>
                                <p>614 26 02 96</p>
                            </div>

                            <div className='contact-item'>
                                <FontAwesomeIcon icon={faQuestionCircle} size="xl"/>
                                <b>{t('contacto_preguntas_frecuentes_titulo')}</b>
                                <p><a href={t('contacto_preguntas_frecuentes_url')}>{t('contacto_preguntas_frecuentes_texto')}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact
