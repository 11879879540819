import React, {Component, useEffect, useState} from 'react'
import './Carousel.css'
import sliderData from './sliderData.js'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { useTranslation } from 'react-i18next';

export default function Carousel(){
    const {t} = useTranslation();
    const [imgIndex, setImgIndex] = useState(0);
    const [h2TransitionClass, setH2TransitionClass] = useState('');
    const [carouselTransitionClass, setCarouselTransitionClass] = useState('');
    const [carouselText, setCarouselText] = useState('');
    const [autoPlay, setAutoPlay] = useState(true);
    const [buttonPressed, setButtonPressed] = useState('next');
    useEffect( () => {
        let button = document.getElementById('btn-left');
        let btnStyle = window.getComputedStyle(button);
        
        const interval = setInterval(() => {
            if(btnStyle.display === 'none' || autoPlay){
                setH2TransitionClass('h2-hidden');
                setCarouselTransitionClass('storefront-hidden')
                setTimeout( () => {
                    setH2TransitionClass('h2-show');
                    setCarouselTransitionClass('storefront-shown')
                }, 100);
                changeImage(imgIndex, 'next');
            }
        }, 6000);
        return () => clearInterval(interval);
        
    })
    useEffect( () => {
        sliderData.forEach(img => {
          const preload = new Image();
          preload.src = img;
        })
      }, [])

    const changeImage = (imgIndex, direction) => {
        setButtonPressed(direction)
        if( direction === 'prev'){
            
            setImgIndex((imgIndex) => (imgIndex === 0 ? sliderData.length - 1 : imgIndex - 1));
            setImgIndex((imgIndex - 1 + sliderData.length) % sliderData.length);
        }
        else if( direction === 'next'){
            
            setImgIndex((imgIndex + 1) % sliderData.length);
        }
        
    }

    const navigate = useNavigate();

    const nextImage = () => {
        setAutoPlay(false);
        setH2TransitionClass('h2-hidden');
        setCarouselTransitionClass('storefront-hidden')
        changeImage(imgIndex, 'next');
        setTimeout( () => {
            setH2TransitionClass('h2-show');
            setCarouselTransitionClass('storefront-shown')
        }, 100);
        
    }

    const prevImage = () => {
        setAutoPlay(false);
        setH2TransitionClass('h2-hidden');
        setCarouselTransitionClass('storefront-hidden')
        changeImage(imgIndex, 'prev');
        setTimeout( () => {
            setH2TransitionClass('h2-show');
            setCarouselTransitionClass('storefront-shown')
        }, 100);
        
    }
    const transition = useTransition(sliderData[imgIndex], {
        from: {opacity: 0, transform: buttonPressed === 'next' ? 'translateX(100%)' : 'translateX(-100%)'},
        enter: {opacity: 1, transform: 'translateX(0)'},
        leave: {opacity: 1, transform: buttonPressed === 'next' ? 'translateX(-100%)' : 'translateX(100%)'},
        key: sliderData[imgIndex].img_url
    })

    const text_transition = useTransition(sliderData[imgIndex].text, {
        from: {opacity: 0, transform: 'translateY(-100%)'},
        enter: {opacity: 1, transform: 'translateY(0)'},
        leave: {opacity: 0, transform: 'translateY(+500px)'},
        key: sliderData[imgIndex].img_url,
        config: {duration: 750},
    })

    return (
        <div className='carousel'>
                <div className={`storefront ${carouselTransitionClass}`}>
                
                {text_transition((style, item) => (
                    <animated.p className='carousel-title' style={style}>{t(item)}</animated.p>
                ))}
                
                <div className='btn-wrapper'><button className='btn btn-big' onClick={()=> navigate('/presupuesto')}>{t("presupuesto_gratis")}</button></div>
                <button className='btn-left btn-carousel' aria-label='swipe left' id='btn-left'>
                    <FontAwesomeIcon icon={faChevronLeft} size='xl' onClick={prevImage} />
                </button>
                <button className='btn-right btn-carousel' aria-label='swipe right' onClick={nextImage}>
                    <FontAwesomeIcon icon={faChevronRight} size='xl' />
                </button>
                {transition((style, item) => (
                    <animated.img className='carousel-img' style={style} src={item.img_url} alt={item.text} />
                ))}
                
            </div>


            
        </div>
    )
}