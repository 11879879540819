import React from 'react'
import Form from '../Form.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './FormSection.css'
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { useTranslation } from 'react-i18next';

function FormSection({bgColor}) {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(()=>{
    Aos.init({duration: 1500})
  })
  return (
    <div>
        <div className='start-your-project' style={{backgroundColor: bgColor}}>
          <div className='project-text-section' data-aos='fade-right'>
            <h2 className='title'>{t('start_your_project_title')}</h2>
            <hr className='h_line_left'/>
            <div className='project-text-wrapper'>
              <p>{t('project_text_1')}</p>
              <p>{t('project_text_2')}</p>
              <div className='project-point'>
                <FontAwesomeIcon icon={faStar} className='star-icon' /><p> {t('project_point_1')}<b>{t('bold_point')}</b>{t("end_of_point_1")}</p>
              </div>
              <div className='project-point'>
              <FontAwesomeIcon icon={faStar} className='star-icon' /><p> {t('project_point_2')}</p>
              </div>
              <div className='project-point'>
              <FontAwesomeIcon icon={faStar} className='star-icon' /><p> {t('project_point_3')}</p>
              </div>
              <div className='project-point'>
              <FontAwesomeIcon icon={faStar} className='star-icon' /><p> {t('project_point_4')}</p>
              </div>
              <p>{t('contact_expert')}</p>
            </div>
          </div>
          <div className='form-wrapper' data-aos='fade-left'>
            <h2 className='title'>{t('get_a_quote_title')}</h2>
            <Form/>
          </div>
        </div>
    </div>
  )
}

export default FormSection
