import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

function Footer() {
  const {t} = useTranslation();
  return (
    <div>
  <footer className='footer'>
    <img src="/final_cropped_logo.png" alt={t('footer_logo_alt')} />
    <p>{t('footer_description')}</p>
    <ul className='social-media'>
      <li>
        <a
          className='link'
          href="https://www.instagram.com/microcementinlondon"
          rel="nofollow"
          aria-label={t('instagram_link_label')}
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} className='brand-icon' size='2xl' />
        </a>
      </li>
      <li>
        <a
          className='link'
          href="https://www.facebook.com/microcementinlondon/"
          rel="nofollow"
          aria-label={t('facebook_link_label')}
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookF} className='brand-icon' size='xl' />
        </a>
      </li>
    </ul>
    <ul className='policies'>
      <li>
        <a href="/privacidad" rel="nofollow">
          {t('privacy_policy_link')}
        </a>
      </li>
      <li>
        <a href="/politica-de-cookies" rel="nofollow">
          {t('cookies_policy_link')}
        </a>
      </li>
    </ul>
  </footer>
</div>
  )
}

export default Footer
