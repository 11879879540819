import React, { useRef } from 'react'
import Footer from '../Elements/Footer.js';
import Navbar from '../Elements/Navbar.js';
import FormSection from '../Elements/FormSection.js';
import './Why.css'
import SmallCarousel from '../Elements/SmallCarousel.js';
import {exampleCarousel1Data, exampleCarousel2Data, exampleCarousel3Data} from '../exampleCarouselData.js'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Why() {
    const {t} = useTranslation();
    const navRef = useRef();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
    <div>
        <Helmet>
            <meta charSet='utf-8'/>
            <title>¿Por qué elegir microcemento para tu reforma? | MicrocementPRO</title>
            <meta name="description" content="Motivos para elegir el microcemento como revestimiento en la reforma de su vivienda, negocio, baño, suelo o cocina"/>
        </Helmet>
        <Navbar navRef={navRef}/>
        <div className='content-wrapper' ref={navRef}>
            <div className='general_info' style={{backgroundColor: "white"}}>
                <h1 className='microcement-title'>{t('por_que_elegir_microcemento')}</h1>
                <p>
                    {t('microcemento_descripcion_1')}
                </p>
                <p>
                    {t('microcemento_descripcion_2')}
                </p>
                <p>
                    {t('microcemento_descripcion_3')}
                </p>
                <p>
                    {t('microcemento_descripcion_4')}
                </p>
            </div>
            <div className='services_section'>
                <h2 className='main-title'>{t('algunos_ejemplos')}</h2>
                <div className='services_wrapper'>
                    <SmallCarousel imgsObj={exampleCarousel1Data} text={t('residencial')}/>
                    <SmallCarousel imgsObj={exampleCarousel2Data} text={t('comercial')} />
                    <SmallCarousel imgsObj={exampleCarousel3Data} text={t('exteriores')}/>
                </div>
                <div className='btn-wrapper btn-2'><a className='embedded-a' href='/proyectos-previos'>{t('ver_trabajos_previos')}</a></div>
            </div>
            
            <FormSection bgColor="#c6c6c6"/>
        </div>
        <Footer/>
    </div>
    )
}

export default Why
