export const exampleCarousel1Data = [
    {
        img_url: "bathroom.jpg",
    },
    {
        img_url: "carousel2.jpg",
    },
    {
        img_url: "carousel4.png",
    },

];
export const exampleCarousel2Data = [
    {
        img_url: "carousel3.jpg",
    },
    {
        img_url: "carousel6.jpg",
    },
    {
        img_url: "carousel7.jpg",
    },

];
export const exampleCarousel3Data = [
    {
        img_url: "exterior.jpg",
        text: "Elegante, sin juntas y totalmente personalizable"
    },
    {
        img_url: "commercial.jpg",
        text: "Instalado por auténticos expertos"
    },
    {
        img_url: "insta3.png",
        text: "Cualquier color, cualquier superficie"
    },

];

