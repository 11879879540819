const colorFiles = [
    "colors/Biscotti-400x320.png",
    "colors/blancpur-400x320.png",
    "colors/Calluna-400x320.png",
    "colors/chardonnay-400x320.png",
    "colors/chestnut-400x320.png",
    "colors/cloudysky-400x320.png",
    "colors/copper-400x320.png",
    "colors/coral-400x320.png",
    "colors/desertsand-400x320.png",
    "colors/dustyvalley-400x320.png",
    "colors/early-sand-400x320.jpg",
    "colors/everest-400x320.png",
    "colors/fawn-400x320.png",
    "colors/himalayabrown-400x320.png",
    "colors/Lagoon-400x320.png",
    "colors/magnoliabloom-400x320.png",
    "colors/maultwaycorrect-400x320.png",
    "colors/mink-400x320.png",
    "colors/naranjaSky-400x320.png",
    "colors/natural-leather-400x320.png",
    "colors/natural-slate-400x320.png",
    "colors/niagra-400x320.png",
    "colors/night-sky-400x320.png",
    "colors/oceanble-400x320.png",
    "colors/palomino-400x320.png",
    "colors/paradise-400x320.png",
    "colors/pavilliongrey-400x320.png",
    "colors/pearl-400x320.png",
    "colors/pine-400x320.png",
    "colors/pink-sky-400x320.png",
    "colors/rustybrown-400x320.png",
    "colors/shadow-grey-400x320.png",
    "colors/softsteel-400x320.png",
    "colors/solarflare-400x320.png",
    "colors/stormysky11-400x320.jpg",
    "colors/sunsetsky-400x320.png",
    "colors/volcanoash-1-400x320.png",
    "colors/walnut-1-400x320.jpg"
  ];

export default colorFiles;