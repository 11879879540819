import React from 'react'
import { useCookies } from 'react-cookie'
import { Cookies } from 'react-cookie-consent'
import './CookieConsent.css'
import * as ReactGA from "react-ga"

function CookieConsent() {
    const [cookies, setCookie] = useCookies(["cookieDecision", "cookieConsent"])
    const handleAcceptCookies = () => {
        setCookie("cookieDecision", true, {path: "/"})
        setCookie("cookieConsent", true, {path: "/"})
        // if (process.env.NODE_ENV === "production" || true) {
        //     ReactGA.initialize(`${process.env.COOKIES_KEY1}`);
        //     ReactGA.initialize(`${process.env.COOKIES_KEY2}`);
        // }
    }

    const handleDeclineCookies = () => {
        Cookies.remove("_ga", {path: '/'})
        Cookies.remove("_gat", {path: '/'})
        Cookies.remove("_gid", {path: '/'})
        setCookie("cookieDecision", true, {path: "/"})
        setCookie("cookieConsent", false, {path: "/"})
    }

    return (
        <div className='cookies-banner'>
            <p>Este sitio web emplea cookies para mejorar la experiencia del usuario. Asegura que estás de acuerdo con nuestra política de uso de cookies. <a href='/politica-de-cookies' rel='nofollow'>Aprender más</a></p>
            <div className='cookies-button-group'>
                <button className='gray' onClick={handleDeclineCookies}>
                    Aceptar Sólo Esenciales
                </button>
                <button onClick={handleAcceptCookies}>
                    Aceptar
                </button>

            </div>
            
        </div>
    )
}

export default CookieConsent
