import React from 'react'
import './Service.css'
import { useTranslation } from 'react-i18next'
export default function Service({translationKey, img_path}) {
    const {t} = useTranslation();
    const title = t(`${translationKey}.title`);
    const text = t(`${translationKey}.text`)
    return (
      <div className='serv-div'>
        <img src={img_path} alt={title}></img>
        <h2>{title}</h2>
        <hr className='h_line'/>
        <p>{text}</p>
      </div>
    )
}
