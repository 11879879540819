import React, { useEffect } from 'react'
import '../App.css';
import Service from '../Service.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import {useRef} from 'react';
import Carousel from '../Carousel.js';
import Navbar from '../Elements/Navbar.js';
import Footer from '../Elements/Footer.js';
import FormSection from '../Elements/FormSection.js';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    useEffect(()=>{
      Aos.init({duration: 1500})
    })

    const navigate = useNavigate();
    const navRef = useRef();
    const imageSources = [
        './logos/hilton.jpg',
        './logos/asos.jpg',
        './logos/breitling.png',
        './logos/canali.jpg',
        './logos/getty.jpg',
        './logos/loccitane.jpg',
        './logos/nusr-et.jpg',
        './logos/polestar.jpg',
        './logos/siemens.jpg',
        './logos/sir-robert-mcalpine.png',
        './logos/starbucks.jpg',
        './logos/zwift.jpg'
      ];

    const companyNames = imageSources.map((imagePath) => {
      return imagePath.split('/')[2].split('.')[0]
    })
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Empresa de reformas con microcemento | MicrocementPro</title>
        <meta name="description" content="MicrocementPro, su empresa de reformas de microcemento con instaladores profesionales para todo tipo de instalaciones con hasta 10 años de garantía"/>
      </Helmet>

      <Navbar navRef={navRef}/>
      <div className='content-wrapper' ref={navRef}>
        
        <Carousel />
        <div className='general_info'>
          <h1 className='main-title'>{t("especialistas")}</h1>
          <p><b>{t("instaladores_calificados")}</b>{t("trabajando_con")}</p>

          <p>{t("el_microcemento_es")}<b>{t("resistentes")}</b></p>

          <p>{t("en_microcementpro")}<b>{t("flexibles")}</b>{t("brindando")}</p>
          <p>{t("boca_a_boca")}</p>
          <p>{t("podemos_ofrecer")}<b>{t("garantia")}</b>{t("inigualable")}<b>{t("instalado_correctamente")}</b>{t("nuestra_dedicacion")}</p>
          <div className='btn-wrapper btn-2'><a className='embedded-a' href='/por-que-elegir-microcemento'>{t("mas_sobre")}</a></div> 
        </div>
        <div className='services_section services-dark-color'>
          <h2 data-aos='fade-right' className='main-title'>{t("nuestros_servicios")}</h2>
          <div className='services_wrapper' data-aos='fade-left'>
            <Service 
              translationKey = {"residential"}
              img_path = {'./bathroom.jpg'}
            />
            <Service
              translationKey = {"commercial"}
              img_path = {"./commercial.jpg"}
            />
            <Service
              translationKey = {"exterior"}
              img_path = {"./exterior.jpg"}
            />
          </div>
          <div className='btn-wrapper'><button className='btn-2' onClick={() => navigate('/proyectos-previos')}>{t("trabajos_previos")}</button></div>
        </div>
        
        <div className='customers'>
          <h2 data-aos='fade-up'>{t("clientes_satisfechos")}</h2>
          <div className='logo-grid' data-aos='zoom-in'>
            {imageSources.map((src, index) => (
            <div key={index}>
              <img src={src} alt={companyNames[index]} />
            </div>
            ))}
          </div>
          
        </div>
        <div className='services_section'>
          <h2 data-aos='fade-right' className='main-title'>{t("abundancia_opciones")}</h2>
          <div className='services_wrapper' data-aos='fade-left'>
            <Service 
              translationKey={"colors"}
              img_path = {'./colours.jpg'}
            />
            <Service
              translationKey={"finishes"}
              img_path = {"./finishes.jpg"}
            />
            <Service
              translationKey={"textures"}
              img_path = {"./textures.jpg"}
            />
          </div>
          <div className='btn-wrapper btn-2'><a className='embedded-a' href='/preguntas-frecuentes'>{t("preguntas_frecuentes")}</a></div>
        </div>
        <div className='name-trust'>
          <div className='inside-section' data-aos='fade-right'>
            <h2 className='main-title'>{t("nombre_confianza")}</h2>
            <hr className='h_line'/>
            <p>{t("a_lo_largo_de")}</p>
            <ul >
            <li><FontAwesomeIcon className='check-icon' icon={faCheck} size='lg' />{t("mas_de_20_anos")}</li>
            <li><FontAwesomeIcon className='check-icon' icon={faCheck} size='lg' />{t("garantia_lider")}</li>
            <li><FontAwesomeIcon className='check-icon' icon={faCheck} size='lg' />{t("cientos_instalaciones")}</li>
            <li><FontAwesomeIcon className='check-icon' icon={faCheck} size='lg' />{t("proveedores_oficiales")}</li>
          </ul>
          <div className='btn-wrapper'><div className='btn btn-large'><a className='embedded-a' href='/presupuesto'>{t("presupuesto_gratis")}</a></div></div>
          </div>
        </div>
        <div className='services_section insta-section'>
          <h2 className='main-title'>{t("siguenos_instagram")}</h2>
          <div className='insta-wrapper'>
              <a className='insta-post'  href="https://www.instagram.com/p/CdYEZG7IHaw/?img_index=1" rel="nofollow" target="_blank">
                <img src="insta1.webp" alt="Tienda Breitling con pared de microcemento"></img>
                <FontAwesomeIcon icon={faClone} size='xl' className='insta-slide-icon' />
              </a>
              <a className='insta-post' href="https://www.instagram.com/p/CdLKRfIonmD/" rel="nofollow" target="_blank">
                <img src="insta2.webp" alt="Galeria Getty Images"></img>
                <FontAwesomeIcon icon={faClone} size='xl' className='insta-slide-icon' />
              </a>
              <a className='insta-post'  href="https://www.instagram.com/p/CcxPP3WoBo9/" rel="nofollow" target="_blank">
                
                <img src="insta3.webp" alt="Puerta de seguridad con pared de microcmento"></img>
                <FontAwesomeIcon icon={faClone} size='xl' className='insta-slide-icon' />
              </a>
              <a className='insta-post' href="https://www.instagram.com/p/Cch2LWUoH_4/" rel="nofollow" target='_blank'>
                
                <img src="insta4.webp" alt="Silla de barberia con suelo de microcemento"></img>
                <FontAwesomeIcon icon={faClone} size='xl' className='insta-slide-icon' />
              </a>
          </div>
          <div className='btn-wrapper btn-2'><a className='link' href="https://www.instagram.com/microcementinlondon" rel="nofollow" target="_blank"><FontAwesomeIcon icon={faInstagram} className='brand-icon' size='xl' />{t("siguenos")}</a></div>

        </div>
        <FormSection bgColor="#c6c6c6"/>
        <Footer/>
      </div>
    
    </div>
  )
}

export default Home

