import React, { useEffect } from 'react'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js'
import './TrabajosPrevios.css'
import Aos from 'aos';
import 'aos/dist/aos.css'
import trabajosData from '../trabajosData.js';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


function TrabajosPrevios() {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const navRef = useRef();
    useEffect(() => {
        window.scrollTo(0, 0)
        Aos.init({duration: 1500})
    }, [])


    var animationDelay = 0;

    return (
        <div>
        <Helmet>
            <meta charSet='utf-8'/>
            <title>Proyectos y reformas con aplicación de microcemento| MicrocementPro</title>
            <meta name="description" content="Nuestros proyectos y reformas aplicando microcemento ya realizados para algunos nuestros clientes tanto a particulares como a empresas y arquitectos"/>
        </Helmet>
        <Navbar navRef ={navRef}/>
        <div className='content-wrapper' ref={navRef}>
            <div className='trabajos-previos' >
                <h1 className='title'>{t("trabajos_previos_titulo")}</h1>
                <div className='projects-grid' data-aos='fade-in'>
                    {trabajosData.map( (proyecto, index)=> {

                        animationDelay = `${index * 100}ms`;
                        
                        return ( <div className='project' key={proyecto.text} style={{animationDelay: animationDelay}} onClick = {()=> navigate(`/proyecto/${index}`)}>
                            <img src={proyecto.img_url} alt={proyecto.alts[0]}/>
                            <p>{proyecto.text}</p>
                            
                        </div>
                    )}
                    )}


                </div>

            </div>
        </div>
        <Footer/>
        </div>
    )
}

export default TrabajosPrevios
