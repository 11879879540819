import React, { useState } from 'react'
import './QuestionAnswer.css'
import { useTranslation } from 'react-i18next';
function QuestionAnswer({question, answer}) {
    const {t} = useTranslation();

    const [isAnswerShown, setIsAnswerShown] = useState(false);
    const showAnswer = () => {
        setIsAnswerShown(!isAnswerShown);
    }
    return (
        <div className='quest-answer-div'>
            <div className='question' onClick={showAnswer}>
                {question}
            </div>
            {
                question === t('ventajas_microcemento') && <div className={`answer ${isAnswerShown ? 'answer-shown' : 'answer-hidden'}`}>
                    <p>{t('ventajas_microcemento_respuesta_1')}</p>
                    <p>{t('ventajas_microcemento_1')}</p>
                    <p>{t('ventajas_microcemento_2')}</p>
                    <p>{t('ventajas_microcemento_3')}</p>
                    <p>{t('ventajas_microcemento_4')}</p>
                    <p>{t('ventajas_microcemento_5')}</p>
                    <p>{t('ventajas_microcemento_6')}</p>
                    <p>{t('ventajas_microcemento_7')}</p>
                </div>
            }
            {
                question === t('resistencia_microcemento') && <div className={`answer ${isAnswerShown ? 'answer-shown' : 'answer-hidden'}`}>
                    <p>{t('resistencia_microcemento_respuesta_1')}</p>
                    <p>{t('resistencia_microcemento_respuesta_2')}</p>
                    <p>{t('resistencia_microcemento_respuesta_3')}</p>
                    <p dangerouslySetInnerHTML={{ __html: t('resistencia_mecanica') }} />
                    <p>{t('resistencia_microcemento_respuesta_4')}</p>
                    <p>{t('resistencia_microcemento_respuesta_5')}</p>
                    <p>{t('resistencia_microcemento_respuesta_6')}</p>
                    <p dangerouslySetInnerHTML={{ __html: t('resistencia_quimica') }} />
                    <p>{t('resistencia_microcemento_respuesta_7')}</p>
                    <p>{t('resistencia_microcemento_respuesta_8')}</p>
                </div>
            }
            {
                question === t('superficies_microcemento') && <div className={`answer ${isAnswerShown ? 'answer-shown' : 'answer-hidden'}`}>
                    <p>{t('superficies_microcemento_respuesta_1')}</p>
                    <p>{t('superficies_microcemento_respuesta_2')}</p>
                    <p>{t('superficies_microcemento_respuesta_3')}</p>
                </div>
            }
            {
                question === t('diferencias_microcemento_hormigon') && <div className={`answer ${isAnswerShown ? 'answer-shown' : 'answer-hidden'}`}>
                    <p>{t('diferencias_microcemento_hormigon_respuesta_1')}</p>
                    <p>{t('diferencias_microcemento_hormigon_respuesta_2')}</p>
                    <p>{t('diferencias_microcemento_hormigon_1')}</p>
                    <p>{t('diferencias_microcemento_hormigon_2')}</p>
                    <p>{t('diferencias_microcemento_hormigon_3')}</p>
                    <p>{t('diferencias_microcemento_hormigon_4')}</p>
                </div>
            }
        </div>
    )
}

export default QuestionAnswer
