import React from 'react'
import { useParams } from 'react-router-dom'
import trabajosData from '../trabajosData.js'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js'
import './ProjectPage.css'
import { useRef } from 'react'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


function ProjectPage() {
    const {id} = useParams()
    const project_info = trabajosData[id]
    const navRef = useRef();

    function handleIDChange(id){
        
        if(id >= trabajosData.length)
            return 0
        else if(id < 0)
            return trabajosData.length - 1
        else
            return id
    }
    var animationDelay = 0;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div>
            <Navbar navRef={navRef}/>
            <div className='content-wrapper' ref={navRef}>
                <div className='project_page' >
                    
                    <h1 className='title'>{project_info.text}</h1>
                    <div className='nav-buttons-div'>
                    <div className='btn-wrapper'><button className='btn-3 move-left'><a className='link' href={`/proyecto/${handleIDChange(parseInt(id)-1)}`} ><FontAwesomeIcon icon={faArrowLeft} size='xl' /> Anterior</a></button></div>
                    <div className='btn-wrapper'><button className='btn-3 move-right'><a className='link' href={`/proyecto/${handleIDChange(parseInt(id)+1)}`} ><FontAwesomeIcon icon={faArrowRight} size='xl' /> Siguiente</a></button></div>
                    </div>
                    <div className='images-grid' >
                        {
                        project_info.images.map( (img, index)=> {
                            animationDelay = `${index * 100}ms`;
                            return (<img style={{animationDelay: animationDelay}} key={img} src={img} alt={project_info.alts[index]}/>)
                        }
                        )}


                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ProjectPage
