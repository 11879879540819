import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { BrowserRouter } from 'react-router-dom';
import "./i18n";

if (process.env.NODE_ENV === 'production')  disableReactDevTools();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

