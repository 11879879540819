import React, { useRef } from 'react'
import Footer from '../Elements/Footer.js';
import Navbar from '../Elements/Navbar.js';
import FormSection from '../Elements/FormSection.js';
import { Helmet } from 'react-helmet';
function QuotePage() {
    const navRef = useRef();
    return (
        <div>
        <Helmet>
            <meta charSet='utf-8'/>
            <title>Solicitar presupuesto empresa de microcemento | MicrocementPro</title>
            <meta name="description" content="Solicitud de presupuestos online. Con MicrocementPro puedes solicitar presupuestos online de microcemento de forma gratuita para tu proyecto o reforma."/>
        </Helmet>
        <Navbar navRef={navRef}/>
        <div className='content-wrapper' ref={navRef}>
            <FormSection bgColor="white"/>
        <Footer/>
        </div>

        </div>
    )
}

export default QuotePage
    