import './App.css';
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './WholePages/Home.js';
import Why from './WholePages/Why.js';
import QuotePage from './WholePages/QuotePage.js';
import TiposMicrocemento from './WholePages/TiposMicrocemento.js';
import FAQ from './WholePages/FAQ.js';
import Colors from './WholePages/Colors.js';
import AboutUs from './WholePages/AboutUs.js';
import Contact from './WholePages/Contact.js';
import ScrollButton from './Elements/ScrollButton.js';
import PrivacyPolicy from './WholePages/PrivacyPolicy.js';
import TrabajosPrevios from './WholePages/TrabajosPrevios.js';
import ProjectPage from './WholePages/ProjectPage.js';
import MicrocementAlicante from './WholePages/MicrocementAlicante.js';
import { useCookies } from 'react-cookie';
import CookieConsent from './Elements/CookieConsent.js';
import CookiePolicy from './WholePages/CookiePolicy.js';

function App() {
  const [cookies] = useCookies(["cookieDecision"])
  const [analyticCookie, setAnalyticCookie, removeAnalyticCookie] = useCookies(["_ga", "_gid", "_ga_ELS7KJK30X", "_ga_6JC3KQ9XKV", "_gcl_au", "_gat_gtag_UA_298669569_1"])
  const hasConsentedToCookies = () => {
    return cookies.cookieConsent;
  };
  const analyticsIsInitialized = () => {
    return analyticCookie._ga
  }
  
  useEffect(() => {
    if (hasConsentedToCookies()) {
      if(!analyticsIsInitialized()){
        console.log("Initializing analytics")
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', `G-6JC3KQ9XKV`, {
          client_storage: 'none'
        });

        
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=G-6JC3KQ9XKV`;
        document.head.appendChild(scriptTag);

        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', `AW-11460770725`, {
          client_storage: 'none'
        });
    
        const scriptTag2 = document.createElement('script');
        scriptTag2.async = true;
        scriptTag2.src = `https://www.googletagmanager.com/gtag/js?id=AW-11460770725`;
        document.head.appendChild(scriptTag2);
      }
      console.log("Analytics initialized")
      
    }
    else{
      console.log("Cookies rejected")
      removeAnalyticCookie("_gid", {path: "/"})
      removeAnalyticCookie("_ga", {path: "/"})
      removeAnalyticCookie("_ga_ELS7KJK30X", {path: "/"})
      removeAnalyticCookie("_ga_6JC3KQ9XKV", {path: "/"})
      removeAnalyticCookie("_gcl_au", {path: "/"})
      removeAnalyticCookie("_gat_gtag_UA_298669569_1", {path: "/"})

    }
  })
  
  return (
    <div className="App">
      <ScrollButton/>
      
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="" element={<Home/>} />
          <Route path="/proyectos-previos" element={<TrabajosPrevios/>}/>
          <Route path="/colores" element={<Colors/>}/>
          <Route path="/nosotros" element={<AboutUs/>} />
          <Route path="/contacto" element={<Contact/>}/>
          <Route path="/presupuesto" element={<QuotePage/>}/>
          <Route path="/por-que-elegir-microcemento" element={<Why/>}/>
          <Route path="/preguntas-frecuentes" element={<FAQ/>}/>
          <Route path="/tipos-microcemento" element={<TiposMicrocemento/>}/>
          <Route path='/privacidad' element={<PrivacyPolicy/>}/>
          <Route path='/proyecto/:id' element={<ProjectPage/>}/>
          <Route path='delegacion/microcemento-alicante' element={<MicrocementAlicante/>}/>
          <Route path='/politica-de-cookies' element={<CookiePolicy/>}/>
        </Routes>

      {!cookies.cookieDecision && <CookieConsent/>}
    </div>
      
  );
}

export default App;
