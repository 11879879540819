import React from 'react';
import {useForm} from "react-hook-form";
import './Form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Form(){
    const {t} = useTranslation();
    const {register, formState: {errors}, handleSubmit} = useForm();

    const onSubmit = async (data) => { 
      console.log(data);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      const response = await fetch(['https://microcement-backend.onrender.com/send'], requestOptions);
      const jsonData = await response;
      if (jsonData.status === 200) {
        alert('Email sent successfully!');
      } else {
        alert('There was an error sending your email.');
      }
      }

    return(
      <form className='quote-form' onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text" id="name" name="name"
        placeholder={t('name_placeholder')}
        {...register('name', {
            required: true
        })}
      />
      {errors.name?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('name_required_error')}</p>}
      <input
        id="email" name="email"
        placeholder={t('email_placeholder')}
        {...register('email', {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i
        })}
      />
      {errors.email?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('email_required_error')}</p>}
      {errors.email?.type === 'pattern' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('email_pattern_error')}</p>}
      <input
        id="phone" name="phone"
        placeholder={t('phone_placeholder')}
        {...register('phone', {
            required: true
        })}
      />
      {errors.phone?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('phone_required_error')}</p>}
      <textarea
        id="description"
        name="description"
        placeholder={t('description_placeholder')}
        {...register('description', {
            required: true
        })}
      />
      {errors.description?.type === 'required' && <p className='error-msg'><FontAwesomeIcon icon={faTriangleExclamation} /> {t('description_required_error')}</p>}
    
      <div className='btn-wrapper'><button className='btn btn-large' type="submit">{t('submit_button')}</button></div>
      <p className='form-privacy'>{t('privacy_text')}</p>
    </form>
    )
}