import React from 'react'
import Navbar from '../Elements/Navbar.js'
import Footer from '../Elements/Footer.js'
import { useRef } from 'react'
import './AboutUs.css'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

function PrivacyPolicy() {
    const navRef = useRef();
    const {t} = useTranslation();
    return (
        <div>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>Nuestra política de privacidad | MicrocementPRO</title>
        </Helmet>
            <Navbar navRef={navRef}/>
            <div className='content-wrapper' ref={navRef}>
            <div className='about-us'>
                <h1 className='title'>{t('privacy_policy_title')}</h1>
                <p>{t('privacy_policy_intro_1')}</p>
                <p>{t('privacy_policy_intro_2')}</p>
                <p>{t('privacy_policy_intro_3')}</p>
                <p>{t('privacy_policy_responsible')}</p>

                <h2>{t('privacy_policy_personal_data_heading')}</h2>
                <p>{t('privacy_policy_personal_data_text_1')}</p>
                <p>{t('privacy_policy_personal_data_text_2')}</p>

                <h2>{t('privacy_policy_data_protection_heading')}</h2>
                <p>{t('privacy_policy_data_protection_text')}</p>

                <h2>{t('privacy_policy_sharing_data_heading')}</h2>
                <p>{t('privacy_policy_sharing_data_text')}</p>

                <h2>{t('privacy_policy_third_party_links_heading')}</h2>
                <p>{t('privacy_policy_third_party_links_text')}</p>

                <h2>{t('privacy_policy_access_information_heading')}</h2>
                <p>{t('privacy_policy_access_information_text')}</p>
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy
